import React, { useState, useEffect } from 'react';
import './DescriptionBox.css';

const DescriptionBox = ({ productId }) => {
    const [longDescription, setLongDescription] = useState('');

    // Determine the base URL dynamically
    const baseURL = window.location.hostname === 'localhost'
        ? 'http://localhost:4000' // Local URL
        : 'https://brillantviewweb-production.up.railway.app'; // Production URL

    // Fetch the longDescription when the component mounts
    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const response = await fetch(`${baseURL}/products/${productId}`);
                const data = await response.json();
                
                if (data.success && data.longDescription) {
                    setLongDescription(data.longDescription); // Set the longDescription in state
                } else {
                    setLongDescription("Description not available.");
                }
            } catch (error) {
                console.error("Error fetching product details:", error);
                setLongDescription("Error fetching description.");
            }
        };

        fetchProductDetails();
    }, [productId, baseURL]); // Runs the effect when productId or baseURL changes

    return (
        <div className="descriptionbox">
            <div className="descriptionbox-navigator">
                <div className="descriptionbox-nav-box">Description</div>
                <div className="descriptionbox-nav-box fade">Review (122)</div>
            </div>
            <div className="descriptionbox-description">
                {/* Render the long description as HTML */}
                <div dangerouslySetInnerHTML={{ __html: longDescription }} />
            </div>
        </div>
    );
};

export default DescriptionBox;