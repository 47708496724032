import React, { useContext } from 'react';
import { ShopContext } from '../Context/ShopContext';
import { useParams } from 'react-router-dom';
import Breadcrum from '../Components/Breadcrums/Breadcrum';
import ProductDisplay from '../Components/ProductDisplay/ProductDisplay';
import DescriptionBox from '../Components/DescriptionBox/DescriptionBox';
import RelatedProduct from '../Components/RelatedProduct/RelatedProduct';

const Product = () => {
  const { all_product } = useContext(ShopContext);
  const { productId } = useParams();
  
  // Find the selected product based on the productId
  const product = all_product.find((e) => e.id === Number(productId));
  
  // Get the category of the selected product
  const category = product ? product.category : null;
  
  return (
    <div>
      <Breadcrum product={product} />
      <ProductDisplay product={product} />
      <DescriptionBox productId={product._id} />
      
      {/* Pass the category to RelatedProduct component */}
      {category && <RelatedProduct category={category} />}
    </div>
  );
};

export default Product;
