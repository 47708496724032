import React, { useState, useContext } from "react";
import "./CartItem.css";
import { ShopContext } from "../../Context/ShopContext";
import MyOrder from "../MyOrder/MyOrder";

const CartItem = () => {
    const { getTotalCartAmount, all_product, cartItems, removeCart } = useContext(ShopContext);
    const [isProcessing, setIsProcessing] = useState(false); // Add loading state
    const totalAmount = getTotalCartAmount();

    // Load Razorpay Script
    const loadRazorpayScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.onload = () => resolve(true);
            script.onerror = () => resolve(false);
            document.body.appendChild(script);
        });
    };

    // Handle Payment
    const handlePayment = async () => {
        if (isProcessing) return;
        setIsProcessing(true);
      
        const res = await loadRazorpayScript();
        if (!res) {
          alert("Razorpay SDK failed to load. Are you online?");
          setIsProcessing(false);
          return;
        }
      
        const baseURL = window.location.hostname === "localhost"
            ? "http://localhost:4000"
            : "https://brillantviewweb-production.up.railway.app";
      
        const userId = localStorage.getItem("user-id");
        const userName = localStorage.getItem("user-name");
        const address = localStorage.getItem("user-address");
      
        const items = all_product
          .filter(product => cartItems[product.id] > 0)
          .map(product => ({
            productId: product._id,
            quantity: cartItems[product.id],
            productName: product.name,
          }));
      
        if (!items.length || !totalAmount) {
          alert("Your cart is empty.");
          setIsProcessing(false);
          return;
        }
      
        try {
          const orderData = await fetch(`${baseURL}/create-order`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ amount: totalAmount * 100 }), // Amount in paise
          }).then((res) => res.json());
      
          const options = {
            key: "rzp_test_GS1EalEPfwGjq3", // Replace with your Razorpay test key
            amount: orderData.amount,
            currency: orderData.currency || "INR",
            name: "Brilliant View",
            description: "Test Transaction",
            order_id: orderData.id, // Razorpay order ID
            handler: async function (response) {
              // Save order in database
              const saveOrderResponse = await fetch(`${baseURL}/save-order`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${localStorage.getItem("auth-token")}`,
                },
                body: JSON.stringify({
                  userId,
                  userName,
                  address,
                  items,
                  paymentId: response.razorpay_payment_id,
                  amount: totalAmount,
                }),
              });
      
              const saveOrderData = await saveOrderResponse.json();
              if (saveOrderData.success) {
                alert("Order successfully placed!");
              } else {
                console.error("Error saving order:", saveOrderData.error);
                alert("Failed to save order. Please contact support.");
              }
              setIsProcessing(false);
            },
            prefill: {
              name: userName || "Test User",
              email: "testuser@example.com",
              contact: "9999999999",
            },
            theme: {
              color: "#3399cc",
            },
          };
      
          const rzp = new window.Razorpay(options);
          rzp.open();
        } catch (error) {
          console.error("Error during payment:", error);
          alert("An error occurred during payment. Please try again.");
          setIsProcessing(false);
        }
      };

    return (
        <div className="cartitem">
            <div className="cartitems-format-main">
                <p>Product</p>
                <p>Title</p>
                <p>Price</p>
                <p>Quantity</p>
                <p>Remove</p>
            </div>
            <hr />
            {all_product.map((product) => {
                if (cartItems[product.id] > 0) {
                    return (
                        <div key={product.id}>
                            <div className="cartitem-format cartitems-format-main">
                                <img src={product.image} alt="" className="carticon-product-icon" />
                                <p>{product.name}</p>
                                <p>₹{product.new_price * cartItems[product.id]}</p>
                                <button className="cartitem-quantity">{cartItems[product.id]}</button>
                                <span
                                    className="cartitem-remove-icon"
                                    onClick={() => removeCart(product.id)}
                                >
                                    ❌
                                </span>
                            </div>
                            <hr />
                        </div>
                    );
                }
                return null;
            })}

            <div className="cartitem-down">
                <div className="cartitem-total">
                    <h1>Cart Total</h1>
                    <p>Total: ₹{totalAmount}</p>
                    <button onClick={handlePayment} disabled={isProcessing}>
                        {isProcessing ? "Processing..." : "PROCEED TO CHECKOUT"}
                    </button>
                </div>
            </div>
            <MyOrder />
        </div>
    );
};

export default CartItem;
